import React from 'react';

const About = () => {
  return (
    <section className="about section-padding style-4">
      <div className="integration">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-head text-center style-4">
             
              <h2 className="mb-20">Who We <span> Are </span> </h2>
              <p>Mutual Trust MFBank Limited is a dynamic financial institution that specializes in providing tailored banking services for individuals and businesses. We believe in building long-lasting relationships with our customers, offering personalized solutions that empower and transform lives.</p>

            </div>
          </div>
        </div>
        <div className="container">
          <div className="content mb-100 pb-100 border-1 border-bottom brd-gray">
            {/* {
              aboutData.integrations.map((item, index) => (
                <div className="img" key={index}>
                  <img src={item} alt="" className="mt-30" />
                </div>
              ))
            } */}
          </div>
        </div>
        <img src="/assets/img/about/intg_back.png" alt="" className="intg-back" />
      </div>
      <div className="content frs-content">
        <div className="container">
          <div className="row align-items-center justify-content-between">
          
            <div className="col-lg-6">
            <div className="">
                <div className="section-head style-4">                 
                  <h2 className="mb-30">How it all <span>Started</span> </h2>
                </div>
                <p className="text mb-20">
                Mutual Trust mfBank Limited is a re-capitalized financial institution with an unwavering focus on empowering households and serving the economic needs of communities at the base of the pyramid. Our mission is we relentlessly seek to exceed customers' expectations through a highly motivated workforce and cutting-edge technology by driving financial inclusion and economic stability through responsible lending and tailored financial solutions.

                </p>
                
                <p className="text mb-20">
                Today, we proudly serve hundreds of thousands of customers, underpinned by a commitment to exceptional customer service, sustainable growth, and delivering superior value to our stakeholders. Our lending model is strategically designed to support civil servants and entrepreneurs seeking to build small businesses and cottage industries. With financing solutions across the agricultural value chain, structured loans, and business support, we are dedicated to promoting small and medium-sized enterprises (SMEs) across Nigeria.

                </p>
                <p className="text mb-20">
                Backed by our shareholders and industry-leading fund placement products, Mutual Trust mfBank Limited is a trusted partner for economic empowerment, ensuring positive impact and long-term success for our clients and communities alike.
                </p>     
              </div>
            </div>
            
            <div className="col-lg-6">
             
              
              <img src="/assets/img/about/ipad.png" alt="" />
                
                           
                
            
            </div>
          </div>
        </div>
        <img src="/assets/img/about/about_s4_lines.png" alt="" className="lines" />
        <img src="/assets/img/about/about_s4_bubble.png" alt="" className="bubble" />
      </div>
    </section>
  )
}

export default About