import React, { useState } from 'react';
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

const AboutHeader = ({  paddingTop }) => {
  const [isOpen, setOpen] = useState(false);

  const openVideo = (e) => {
    e.preventDefault();
    setOpen(true);
  }

  return (
    <header className={`about-page-sec style-5 ${paddingTop ? 'pt-100':''}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="info" style={{ background: '#00000000'  }}>
              <h1>{  <>We specialize in top-notch <br /> banking services</> }
                <span className="ms-2">
                             
                  <img src="/assets/img/header/head5_line.png" alt="" className="head-line" />
                  <img src="/assets/img/header/head5_pen.png" alt="" className="head-pen" />
                </span>
              </h1>
              <p>
                {  'At Mutual Trust MFBank Limited, we are committed to empowering individuals and businesses through innovative banking solutions. Our customer-centric approach and robust financial products help you grow and achieve your financial goals. With a focus on excellence, integrity, and innovation, we strive to deliver the best banking experience in Nigeria.' } <br /> 
              </p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="main-vid">
              <img src="/assets/img/vid_banner5.png" alt="" />
              <a href="https://youtu.be/6zJkFO2CB1I" onClick={openVideo} className="play-icon">
                <i className="fas fa-play"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
     
     
      
      {
        typeof window !== "undefined" && 
          (
            <ModalVideo
              channel="youtube"
              autoplay
              isOpen={isOpen}
              videoId="6zJkFO2CB1I"
              onClose={() => setOpen(false)}
            />
          )
      }
    </header>
  )
}

export default AboutHeader