import React, { useEffect, useRef } from 'react';
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import Navbar from 'components/Navbars/ITCreativeNav';
import About from 'components/App/About2';
import Footer from 'components/Saas/Footer';
import AboutHeader from 'components/Saas/AboutHeader';
import Aboutw from 'components/Style2/About/About';
import SideMenu from 'components/ITCreative/SideMenu';



const PageAboutApp = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  return (
    <MainLayout>     
      <Navbar navbarRef={navbarRef} />
      
      <main className="about-page style-5">
        <AboutHeader />
        <About />
        <Aboutw />


      </main>
      <Footer noWave />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title>Mutual Trust MFBank</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />      
    </>
  )
}

export default PageAboutApp;